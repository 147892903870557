<!--渠道兑换码管理-->
<template>
  <div class="channelCode">
    <section class="table-search">
      <el-button type="primary" @click="goClick(0)">导出</el-button>
      <el-button type="primary" @click="goClick(1)">添加渠道兑换码</el-button>
    </section>
    <section class="table-search">
      <div class="search-L">
        <el-input
          v-model="tableParams.kw"
          placeholder="请输入渠道名称"
          @input="kwChange"
          clearable
          class="mr15"
        />
        <el-select
          v-model="tableParams.cardId"
          placeholder="请选择对应卡券"
          @change="reload"
          clearable
          class="mr15"
        >
          <el-option
            v-for="item in allCardList"
            :key="item.cardId"
            :label="item.cardName"
            :value="item.cardId"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="tableParams.usage"
          placeholder="请选择使用情况"
          @change="reload"
          clearable
          class="mr15"
        >
          <el-option
            v-for="item in ObjToOpt(useStatusObj)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="tableParams.codeStatus"
          placeholder="请选择兑换码状态"
          @change="reload"
          clearable
          class="mr15"
        >
          <el-option
            v-for="item in ObjToOpt(codeStatusObj)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <el-date-picker
        v-model="dateToDate"
        type="daterange"
        align="center"
        unlink-panels
        range-separator="~"
        start-placeholder="生成日期"
        end-placeholder="生成日期"
        :picker-options="pickerOptions"
        @change="reload"
        clearable
        value-format="yyyy-MM-dd"
      >
      </el-date-picker>
    </section>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="生成时间" align="center" width="80">
        <template slot-scope="scope">
          <span>{{ scope.row.addDt }}</span>
        </template>
      </el-table-column>
      <el-table-column label="有效期至" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.startDate }}～{{ scope.row.endDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="渠道名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.channelName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="对应卡券" min-width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.cardName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="使用情况" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.codeUseNum }}/{{ scope.row.codeNum }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <span>{{ codeStatusObj[scope.row.codeStatus] }}</span>
        </template>
      </el-table-column>
      <el-table-column label="二维码" align="center">
        <template slot-scope="scope">
          <el-popover placement="top" width="240" v-model="scope.row.visible">
            <el-image
              style="width: 240px; height: 240px"
              :src="imgUrl"
            ></el-image>
            <img
              style="width: 24px; height: 24px"
              slot="reference"
              @click="getQrCode(scope.row)"
              src="@/assets/images/card/qrcode.png"
              alt=""
            />
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" min-width="140">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="medium"
            @click.stop="goClick(2, scope.row)"
            >换绑</el-button
          >
          <el-button
            type="text"
            size="medium"
            v-if="scope.row.codeStatus == 0"
            @click.stop="goClick(3, scope.row)"
            >启用</el-button
          >
          <el-button
            type="text"
            size="medium"
            v-if="scope.row.codeStatus == 1"
            @click.stop="goClick(4, scope.row)"
            >停用</el-button
          >
          <el-button
            type="text"
            size="medium"
            v-if="scope.row.codeUseNum > 0"
            @click.stop="goClick(5, scope.row)"
            >兑换记录</el-button
          >
          <el-button
            type="text"
            size="medium"
            v-if="scope.row.codeUseNum == 0"
            @click.stop="goClick(6, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.pageSize"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </section>
    <!-- 编辑弹框 -->
    <el-dialog
      title="添加渠道兑换码"
      :visible.sync="editShow"
      :close-on-click-modal="false"
      append-to-body
      width="600px"
    >
      <!-- 表单提交 -->
      <el-form
        :model="params"
        :rules="rules"
        ref="form-ref"
        label-width="100px"
      >
        <el-form-item label="生成门店">
          <div style="width: 350px">
            {{ params.merchantName }}（{{ params.shopName }}）
          </div>
        </el-form-item>
        <el-form-item label="对应卡券" prop="selectedCardName">
          <div style="display: flex; justify-content: flex-start">
            <el-input
              v-model="params.selectedCardName"
              placeholder="请选择对应卡券"
              disabled
              style="min-width: 240px; margin-right: 10px"
            >
            </el-input>
            <el-button type="primary" @click="goOpenSelectCard()"
              >选择卡券</el-button
            >
          </div>
        </el-form-item>
        <el-form-item label="总兑换次数" prop="codeNum">
          <el-input
            v-model="params.codeNum"
            placeholder="请输入总兑换次数"
            oninput="value=value.replace(/\D|^0/g, '')"
            clearable
            style="width: 350px"
          />
        </el-form-item>
        <div style="margin: 0 0 20px 100px">Tips：每个兑换码可兑换的次数</div>
        <el-form-item label="兑换有效期" prop="dateToDate">
          <el-date-picker
            v-model="params.dateToDate"
            type="daterange"
            align="center"
            unlink-panels
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions2"
            clearable
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="渠道名称" prop="channelName">
          <el-input
            v-model="params.channelName"
            placeholder="请输入渠道名称"
            clearable
            maxlength="5"
            style="width: 350px"
          />
        </el-form-item>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmit('form-ref')"
            >生成渠道码</el-button
          >
        </section>
      </el-form>
    </el-dialog>
    <!-- 选择卡券 -->
    <el-dialog
      title="选择卡券"
      :visible.sync="cardShow"
      :close-on-click-modal="false"
      append-to-body
      width="1200px"
    >
      <!-- 筛选 -->
      <section
        class="table-search"
        style="padding-bottom: 0.15rem; border-bottom: 1px solid #eee"
      >
        <div class="select-title">适用门店：{{ params.shopName }}</div>
        <div class="search-L">
          <el-select
            v-model="formCardParams.serviceId"
            placeholder="请选择服务内容"
            @change="getCardList"
            clearable
            class="mr15"
          >
            <el-option
              v-for="item in serviceList"
              :key="item.serviceId"
              :label="item.serviceName"
              :value="item.serviceId"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="formCardParams.cardType"
            placeholder="请选择卡券类型"
            @change="getCardList"
            clearable
            class="mr15"
          >
            <el-option
              v-for="item in ObjToOpt(cardTypeObj)"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </section>
      <!-- 表单提交 -->
      <el-form
        :model="params"
        :rules="rules"
        label-width="100px"
        v-if="cardList.length"
      >
        <section class="form-main">
          <el-form-item label="卡券列表" prop="cardId" class="long-content">
            <el-radio-group v-model="params.cardId" @change="goSelectCard">
              <div
                class="shop-item"
                :span="6"
                v-for="item in cardList"
                :key="item.cardId"
              >
                <el-radio-button border :label="item.cardId">{{
                  item.cardName
                }}</el-radio-button>
              </div>
            </el-radio-group>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="goSaveCard()">保存</el-button>
        </section>
      </el-form>
      <el-empty v-else description="暂无卡券，请先添加卡券"></el-empty>
    </el-dialog>
    <!--兑换记录-->
    <el-dialog
      title="兑换记录"
      :visible.sync="exchangeShow"
      :close-on-click-modal="false"
      append-to-body
      width="800px"
    >
      <section class="table-search">
        <div class="search-L">
          <el-input
            v-model="exchangeObj.kw"
            @input="exchangeObjKwChange"
            placeholder="请输入手机号"
            clearable
            class="mr15"
          />
        </div>
      </section>
      <!-- 表格 -->
      <el-table
        :data="exchangeData"
        border
        stripe
        :header-cell-style="{
          background: '#FAFAFA',
          color: '#000000',
        }"
      >
        <el-table-column label="兑换时间" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.addDt }}</span>
          </template>
        </el-table-column>
        <el-table-column label="兑换人" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.userName }}｜{{ scope.row.mobile }}</span>
          </template>
        </el-table-column>
        <el-table-column label="兑换卡券" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.cardName }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <section class="table-footer">
        <el-pagination
          layout="total,sizes, prev, pager, next,jumper"
          background
          :total="exchangeTotal"
          :page-size="exchangeObj.pageSize"
          @size-change="exchangeSizeChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="exchangePage"
          @current-change="exchangeCurrentPage"
        >
        </el-pagination>
      </section>
    </el-dialog>
  </div>
</template>
<script>
import {
  pickerOptions,
  useStatusObj,
  codeStatusObj,
  cardTypeObj,
} from "@/db/objs";
import { ObjToOpt } from "@/utils/utils";
import { rules } from "@/db/rules";
import {
  getChannelCodeList,
  goCreateChannelCode,
  goEnableChannelCode,
  goRemoveChannelCode,
  goUpdateBindChannelCode,
  getAllCardList,
  getQrCode,
  goExportQrCodeList,
  getExchangeRecord,
} from "@/api/card/channel.js";
import { getCardList } from "@/api/group/dianPingCard";
import { getServiceList } from "@/api/resource/room";
export default {
  name: "ChannelCode",
  data() {
    return {
      rules,
      pickerOptions,
      useStatusObj,
      codeStatusObj,
      cardTypeObj,
      ObjToOpt,
      tableParams: {
        shopId: "",
        merchantId: "",
        page: 1,
        pageSize: 10,
        kw: "",
        cardId: "",
        usage: "",
        codeStatus: "",
        shopId: "",
      },
      tableData: [],
      total: 0,
      currentPage: 1,
      allCardList: [],
      cardList: [],
      dateToDate: [],
      editShow: false,

      params: {
        merchantId: "",
        shopId: "",
        shopName: "",
        merchantName: "",
        cardId: "",
        selectedCardName: "",
        channelCodeId: "",
        codeNum: "",
        dateToDate: [],
        startDate: "",
        endDate: "",
        channelName: "",
      },
      pickerOptions2: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      serviceList: [],
      cardShow: false,
      formCardParams: {
        serviceId: "",
        cardType: "",
      },
      addType: 1,
      imgUrl: "",

      exchangeShow: false,
      exchangeData: [],
      exchangeTotal: 0,
      exchangeObj: {
        kw: "",
        page: 1,
        pageSize: 10,
        channelCodeId: "",
      },
      exchangePage: 1,
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
    this.getChannelCodeList();
    this.getAllCardList();
  },
  methods: {
    getExchangeRecord() {
      let params = {
        kw: this.exchangeObj.kw,
        page: this.exchangeObj.page,
        pageSize: this.exchangeObj.pageSize,
        channelCodeId: this.exchangeObj.channelCodeId,
      };
      getExchangeRecord(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.exchangeData = res.data.list;
          this.exchangeTotal = res.data.total;
          if (!this.exchangeShow) {
            this.exchangeShow = true;
          }
        }
      });
    },
    // 导出
    goExportQrCodeList() {
      let params = {
        shopId: this.tableParams.shopId,
        merchantId: this.tableParams.merchantId,
        kw: this.tableParams.kw,
        page: this.tableParams.page,
        pageSize: this.tableParams.pageSize,
        cardId: this.tableParams.cardId,
        usage: this.tableParams.usage,
        codeStatus: this.tableParams.codeStatus,
        searchStartTime:
          this.dateToDate && this.dateToDate.length ? this.dateToDate[0] : "",
        searchEndTime:
          this.dateToDate && this.dateToDate.length ? this.dateToDate[1] : "",
      };
      let name = "渠道兑换码";
      goExportQrCodeList(params, name);
    },
    // 查看二维码
    getQrCode(row) {
      this.imgUrl = "";
      let params = {
        channelCodeId: row.channelCodeId,
      };
      getQrCode(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.imgUrl = res.data;
          row.visible = true;
        }
      });
    },
    // 换绑
    goUpdateBindChannelCode() {
      let params = {
        channelCodeId: this.params.channelCodeId,
        cardId: this.params.cardId,
      };
      goUpdateBindChannelCode(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.cardShow = false;
          this.getChannelCodeList();
        }
      });
    },
    // 查询所有卡券列表
    getAllCardList() {
      let params = {
        merchantId: this.tableParams.merchantId,
        shopId: this.tableParams.shopId,
      };
      getAllCardList(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.allCardList = res.data;
        }
      });
    },
    // 查询兑换码列表
    getChannelCodeList() {
      let params = {
        shopId: this.tableParams.shopId,
        merchantId: this.tableParams.merchantId,
        kw: this.tableParams.kw,
        page: this.tableParams.page,
        pageSize: this.tableParams.pageSize,
        cardId: this.tableParams.cardId,
        usage: this.tableParams.usage,
        codeStatus: this.tableParams.codeStatus,
        searchStartTime:
          this.dateToDate && this.dateToDate.length ? this.dateToDate[0] : "",
        searchEndTime:
          this.dateToDate && this.dateToDate.length ? this.dateToDate[1] : "",
      };
      getChannelCodeList(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 停用启用
    goEnableChannelCode(channelCodeId, codeStatus) {
      let params = {
        channelCodeId: channelCodeId,
        codeStatus: codeStatus,
      };
      goEnableChannelCode(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getChannelCodeList();
        }
      });
    },
    // 删除兑换码
    goRemoveChannelCode(channelCodeId) {
      let params = {
        channelCodeId: channelCodeId,
      };
      goRemoveChannelCode(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getChannelCodeList();
        }
      });
    },
    // 生成渠道兑换码
    goCreateChannelCode() {
      let params = {
        merchantId: this.params.merchantId,
        shopId: this.params.shopId,
        cardId: this.params.cardId,
        codeNum: this.params.codeNum,
        startDate: this.params.dateToDate[0],
        endDate: this.params.dateToDate[1],
        channelName: this.params.channelName,
      };
      goCreateChannelCode(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getChannelCodeList();
          this.editShow = false;
        }
      });
    },
    // 选择卡券
    goSelectCard() {
      this.cardList.forEach((i) => {
        if (i.cardId == this.params.cardId) {
          this.params.selectedCardName = i.cardName;
        }
      });
    },
    // 保存选择的卡券
    goSaveCard() {
      if (this.params.cardId) {
        if (this.addType == 1) {
          this.cardShow = false;
        } else {
          // 换绑
          this.goUpdateBindChannelCode();
        }
      } else {
        this.$message({
          message: "请选择卡券",
          type: "error",
        });
      }
    },
    // 弹出选择卡券弹框
    goOpenSelectCard() {
      this.getCardList();
    },
    // 【请求】服务列表
    getServiceList() {
      let data = {
        shopId: this.params.shopId,
      };
      getServiceList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.serviceList = res.data;
        }
      });
    },
    // 【查询卡券列表
    getCardList() {
      let data = {
        shopId: this.params.shopId,
        serviceId: this.formCardParams.serviceId,
        cardType: this.formCardParams.cardType,
      };
      getCardList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.cardList = res.data;
          this.cardShow = true;
        }
      });
    },
    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.goCreateChannelCode();
        } else {
          return false;
        }
      });
    },
    goClick(val, row) {
      // 0：导出 1:新增 2:换绑 3:启用 4:停用 5 兑换记录 6:删除
      let merchantShopInfo = JSON.parse(
        window.localStorage.getItem("playOne-store-merchantShopInfo")
      );

      switch (val) {
        case 0:
          this.goExportQrCodeList();
          break;
        case 1:
          this.params = {
            cardId: "",
            selectedCardName: "",
            channelCodeId: "",
            codeNum: "",
            dateToDate: [],
            startDate: "",
            endDate: "",
            channelName: "",
          };
          this.params.merchantId = merchantShopInfo.merchantId;
          this.params.merchantName = merchantShopInfo.merchantName;

          this.params.shopId = merchantShopInfo.shopId;
          this.params.shopName = merchantShopInfo.shopName;
          this.addType = 1;
          this.getServiceList();
          this.editShow = true;
          break;
        case 2:
          this.addType = 2;
          this.params.channelCodeId = row.channelCodeId;
          this.params.cardId = row.cardId;
          this.getServiceList();
          this.getCardList();
          break;
        case 3:
        case 4:
          let operationName = val == 3 ? "启用" : "停用";
          let codeStatus = val == 3 ? 1 : 0;
          this.$confirm(
            `是否${operationName}该兑换码?`,
            `${operationName}兑换码`,
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          ).then(() => {
            this.goEnableChannelCode(row.channelCodeId, codeStatus);
          });
          break;
        case 5:
          this.exchangeObj.channelCodeId = row.channelCodeId;
          this.getExchangeRecord();
          break;
        case 6:
          this.$confirm(`是否删除该兑换码?`, `删除兑换码?`, {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.goRemoveChannelCode(row.channelCodeId);
          });
          break;
      }
    },
    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    exchangeObjKwChange: _.debounce(function () {
      this.exchangeObj.page = 1;
      this.exchangePage = 1;
      this.getExchangeRecord();
    }, 500),
    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getChannelCodeList();
    },
    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getChannelCodeList();
    },
    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.pageSize = size;
      this.reload();
    },

    // 【监听】表格分页点击
    exchangeCurrentPage(page) {
      this.exchangeObj.page = page;
      this.getExchangeRecord();
    },
    // 【监听】表格条数点击
    exchangeSizeChange(size) {
      this.exchangeObj.pageSize = size;
      this.getExchangeRecord();
    },
  },
};
</script>
<style lang="scss" scoped>
.channelCode {
  padding: 0.15rem;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  display: flex;
  align-items: center;

  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
}
.select-title {
  width: fit-content;
  margin-right: 0.15rem;
}
.form-footer {
  padding: 0.15rem;
  display: flex;
  justify-content: center;
}
.el-radio-group {
  width: 100%;
}

.shop-item {
  display: inline-flex;
  margin-right: 0.1rem;
  margin-bottom: 0.1rem;
}
</style>